const API_URL = process.env.VUE_APP_API_URL
const API_VERSION = process.env.VUE_APP_API_VERSION

const API_ROUTES = {
	employee: {
		get: `${API_URL}/${API_VERSION}/employee`,
		save: `${API_URL}/${API_VERSION}/employee`,
		delete: `${API_URL}/${API_VERSION}/employee`,
		update: `${API_URL}/${API_VERSION}/employee`,
	},
	workshift: {
		get: `${API_URL}/${API_VERSION}/workshift`,
		save: `${API_URL}/${API_VERSION}/workshift`,
		delete: `${API_URL}/${API_VERSION}/workshift`,
		update: `${API_URL}/${API_VERSION}/workshift`,
	},
	specialty: {
		get: `${API_URL}/${API_VERSION}/specialties`,
		save: `${API_URL}/${API_VERSION}/specialties`,
	},
}

export { API_ROUTES }
